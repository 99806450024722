



































import { Component, Vue } from 'vue-property-decorator';
import './scss/PopupSetDocsStatus.scss';
import { IProject } from '@store/modules/project/Interfaces';
import { namespace } from 'vuex-class';
import { ProjectDocumentsActions } from '@store/modules/project-documents/Types';
import { IStatus } from '@store/modules/project-documents/Interfaces';
import { createStatusConditions } from '@utility/createStatusConditions';

const NSProject = namespace('storeProject');
const NSAboutProject = namespace('storeAboutProject');
const NSDocuments = namespace('storeProjectDocuments');

@Component({
    name: 'PopupSetDocsStatus',
    components: {
        BasePopup: () => import('@components/popups/BasePopup.vue'),
        BaseButton: () => import('@components/buttons/BaseButton.vue'),
        BaseInput: () => import('@components/BaseInputs/BaseInput.vue'),
        BaseSelect: () => import('@components/BaseSelect/BaseSelect.vue'),
        BaseFileInput: () => import('@components/BaseFileInput/BaseFileInput.vue'),
        PopupConfirmation: () => import('@components/popups/PopupConfirmation.vue'),
    }
})

export default class PopupSetDocsStatus extends Vue {
    $refs!: {
        basePopup: HTMLFormElement,
    }

    @NSProject.Getter('projectData') projectData!: IProject;
    @NSDocuments.Getter('statuses') statuses!: IStatus[];
    @NSDocuments.Action(ProjectDocumentsActions.A_CHANGE_DOCUMENT_STATUS)
    changeDocumentStatus!: (payload: {
        projectId: number;
        documentId: string[];
        statusId: string;
    }) => Promise<void>;

    btnDisabled = false;
    statusConditions: { [key: string]: string[] } = createStatusConditions();
    selectedDocuments: any[] = [];
    selectedStatus = {} as IStatus;

    get statusOptions() {
        const statusValues: string[] = this.statusConditions[
            this.selectedDocuments[0] && this.selectedDocuments[0].status ?
                this.selectedDocuments[0].status.title : 'Статус'
            ];
        const output:IStatus[] = [];
        this.statuses.forEach((item) => {
            if (statusValues && statusValues.indexOf(item.title) !== -1) {
                output.push(item);
            }
        })
        return output;
    }

    get activeStatus() {
        if (this.selectedStatus && this.selectedStatus.title) {
            return this.selectedStatus.title;
        } else if (this.selectedDocuments[0] && this.selectedDocuments[0].status) {
            return this.selectedDocuments[0].status.title;
        }
        return 'Статус';
    }


    onSelectStatus(e) {
        this.selectedStatus = JSON.parse(JSON.stringify(e));
    }

    openPopup(popupData) {
        if (this.$refs.basePopup) {
            this.$refs.basePopup.openPopup();
        }
        this.setPopupData(popupData);
    }

    setPopupData(data) {
        this.selectedDocuments = data;
        this.statusConditions = createStatusConditions(data[0].status.title === 'Завершено');
    }

    closePopup() {
        if (this.$refs.basePopup) {
            this.$refs.basePopup.closePopup();
        }
    }

    setStatusMethod() {
        if (this.selectedStatus && this.selectedStatus.id) {
            this.btnDisabled = true;
            this.changeDocumentStatus({
                projectId: this.projectData.id,
                documentId: this.selectedDocuments.map(item => item.uuid),
                statusId: this.selectedStatus.id,
            }).then(() => {
                this.$emit('success-set-docs-status');
            }).finally(() => {
                this.btnDisabled = false;
                this.resetFields();
            });
        }
    }

    resetFields() {
        this.selectedStatus = {
            blocked: false,
            color: '',
            id: '',
            title: '',
        };
    }
}
